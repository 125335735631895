// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import { addUser, getUser } from "services/user.services";
import TemplateDemo from "../../components/Page/UploadImg";
import SelectEstado from "../../components/SelectEstado";
import SelectPaises from "../../components/SelectPaises";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import axios from "axios";

const Profile = () => {
  const [images, setImages] = useState(getUser()?.imgs);
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [cpfcnpj, setCpfCnpj] = useState("");
  const [rg, setRg] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmaSenha, setConfirmaSenha] = useState("");

  const [orgaoEmissorRg, setOrgaoEmissorRg] = useState("");
  const [ufExpedicaoRg, setUfExpedicaoRg] = useState("");
  const [nacionalidade, setNacionalidade] = useState("");
  const [sexo, setSexo] = useState("");
  const [estadoCivil, setEstadoCivil] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [naturalidade, setNaturalidade] = useState("");
  const [nomeMae, setNomeMae] = useState("");
  const [nomePai, setNomePai] = useState("");

  const handleChangeData = async (event) => {
    event.preventDefault();
    if (confirmaSenha !== senha) {
      toast.error("A senha deve ser igual a confirmação de senha");
      return;
    }

    let user = {
      nome: nome,
      sobrenome: sobrenome,
      cpfcnpj: cpfcnpj,
      email: email,
      telefone: telefone,
      senha: senha,
      rg: rg,
      orgaoEmissorRg: orgaoEmissorRg,
      ufExpedicaoRg: ufExpedicaoRg,
      nacionalidade: nacionalidade,
      sexo: sexo,
      estadoCivil: estadoCivil,
      dataNascimento: dataNascimento,
      naturalidade: naturalidade,
      nomeMae: nomeMae,
      nomePai: nomePai,
    };

    const changedFields = Object.keys(user).filter((key) => user[key] !== "");
    const changedData = {};
    changedFields.forEach((key) => {
      changedData[key] = user[key];
    });

    if (changedFields.length < 1) return;
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/usuarios/`,
        changedData
      );
      toast.success(`Dados alterados com sucesso.`);
    } catch (error) {
      if (error?.response?.status !== 200) {
        toast.error(`Erro: ${error.response.data.message}`);
      }
      console.error("Erro:", error);
    }
  };

  const handleAlteraImagePerfil = async (index, image) => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/img/perfil/${image.id}`
      );
      getAllDataAndRefreshImg();
      toast.success(`Imagem de perfil alterada com sucesso!`);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.error("Erro ao alterar imagem de perfil:", error);
    }
  };

  const handleDeleteImage = async (index, image) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/img/${image.id}`
      );
      getAllDataAndRefreshImg();
      toast.success(`Imagem deletada com sucesso!`);
    } catch (error) {
      console.error("Erro ao excluir imagem:", error);
    }
  };

  useEffect(() => {
    getAllDataAndRefreshImg();
  }, []);

  const getAllDataAndRefreshImg = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/usuarios/todos-meus-dados/`
      );
      addUser(response.data);
      setImages(getUser()?.imgs);
    } catch {}
  };

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <Form onSubmit={handleChangeData}>
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Minha conta</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <h6 className="heading-small text-muted mb-4">
                    Informações do usuário
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-cpf-cnpj"
                          >
                            CPF/CNPJ *
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={getUser()?.cpfcnpj}
                            id="input-cpf-cnpj"
                            placeholder="000.000.000-00"
                            type="text"
                            onChange={(e) => setCpfCnpj(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-rg"
                          >
                            RG
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={getUser()?.rg}
                            id="input-rg"
                            placeholder="000000"
                            type="text"
                            onChange={(e) => setRg(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-orgao-emissor"
                          >
                            Órgão Emissor
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={getUser()?.orgaoEmissorRg}
                            id="input-orgao-emissor"
                            placeholder="SSP"
                            type="text"
                            onChange={(e) => setOrgaoEmissorRg(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-cpf-cnpj"
                          >
                            UF de Expedição
                          </label>
                          <SelectEstado
                            selectedValue={getUser()?.ufExpedicaoRg}
                            onValueChange={(e) => {
                              setUfExpedicaoRg(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-nome"
                          >
                            Primeiro nome/Nome Fantasia
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={getUser()?.nome}
                            id="input-nome"
                            placeholder="Nome/Nome Fantasia"
                            type="text"
                            onChange={(e) => setNome(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Sobrenome
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={getUser()?.sobrenome}
                            id="input-last-name"
                            placeholder="Sobrenome"
                            type="text"
                            onChange={(e) => setSobrenome(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-nome-da-mae"
                          >
                            Nome da mãe
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={getUser()?.nomeMae}
                            id="input-nome-da-mae"
                            placeholder="Maria"
                            type="text"
                            onChange={(e) => setNomeMae(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-nome-do-pai"
                          >
                            Nome do pai
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={getUser()?.nomePai}
                            id="input-nome-do-pai"
                            placeholder="José"
                            type="text"
                            onChange={(e) => setNomePai(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-naturalidade"
                          >
                            Naturalidade
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={getUser()?.naturalidade}
                            id="input-naturalidade"
                            placeholder="Barros Cassal"
                            type="text"
                            onChange={(e) => setNaturalidade(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-naturalidade"
                          >
                            Nacionalidade
                          </label>
                          <SelectPaises
                            selectedValue={getUser()?.nacionalidade}
                            onValueChange={(e) =>
                              setNacionalidade(e.target.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="sexo"
                          >
                            Sexo
                          </label>
                          <Input
                            className="mb-3"
                            type="select"
                            name="sexo"
                            id="sexo"
                            defaultValue={getUser()?.sexo}
                            onChange={(e) => setSexo(e.target.value)}
                          >
                            <option value="">Selecione...</option>
                            <option value="F">Feminino</option>
                            <option value="M">Masculino</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-naturalidade"
                          >
                            Estado Civil
                          </label>
                          <Input
                            className="mb-3"
                            type="select"
                            name="estadocivil"
                            id="estadocivil"
                            defaultValue={getUser()?.estadoCivil}
                            onChange={(e) => setEstadoCivil(e.target.value)}
                          >
                            <option value="">Selecione...</option>
                            <option value="solteiro">Solteiro(a)</option>
                            <option value="casado">Casado(a)</option>
                            <option value="divorciado">Divorciado(a)</option>
                            <option value="viuvo">Viúvo(a)</option>
                            <option value="separado">Separado(a)</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-naturalidade"
                          >
                            Data de nascimento
                          </label>
                          <Input
                            type="date"
                            name="dataNascimento"
                            id="dataNascimento"
                            defaultValue={getUser()?.dataNascimento}
                            onChange={(e) => setDataNascimento(e.target.value)}
                            max={new Date().toISOString().split('T')[0]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Authentication  */}
                  <h6 className="heading-small text-muted mb-4">
                    Informações de acesso
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-senha"
                          >
                            Senha
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-senha"
                            placeholder="Senha"
                            type="password"
                            onChange={(e) => setSenha(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-confirmasenha"
                          >
                            Confirmação de senha
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-confirmasenha"
                            placeholder="Confirmação de senha"
                            type="password"
                            autoComplete="off"
                            onChange={(e) => setConfirmaSenha(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Contact Information */}
                  <h6 className="heading-small text-muted mb-4">
                    Informações de contato
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            E-mail
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={getUser()?.email}
                            id="input-email"
                            placeholder="exemplo@aute.dev"
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-telefone"
                          >
                            Telefone
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={getUser()?.telefone}
                            id="input-telefone"
                            placeholder="66999999999"
                            type="phone"
                            onChange={(e) => setTelefone(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Button color="info" type="submit" className="mr-auto">
                      Salvar perfil
                    </Button>
                  </div>
                  {getUser()?.tipo === "pousada" && (
                    <div>
                      <hr className="my-4" />
                      <hr className="my-4" />
                      {/* Description */}

                      <h6 className="heading-small text-muted mb-4">Fotos</h6>
                      <div className="pl-lg-4">
                        <FormGroup>
                          <label>Fotos de pousada</label>
                        </FormGroup>
                        <div className="row">
                          {images.map((image, index) => (
                            <div key={index} className="col-md-2 mb-3">
                              <div className="position-relative">
                                <img
                                  key={index}
                                  src={image.pathCompleto}
                                  alt=""
                                  className="img-fluid"
                                  style={{ width: "100%", height: "auto" }}
                                  // className="m-1 col-sm-6 col-md-2"
                                  // className=" col-sm-12 col-md-2"
                                />
                                <div
                                  className="icone-lixeira position-absolute top-0 end-0"
                                  onClick={() =>
                                    handleDeleteImage(index, image)
                                  }
                                  title="Excluir"
                                >
                                  <i
                                    className="fas fa-trash"
                                    title="Excluir"
                                    alt="Excluir"
                                  ></i>
                                </div>
                                <div
                                  className="icone-lixeira position-absolute top-0 end-0 icone-perfil"
                                  onClick={() =>
                                    handleAlteraImagePerfil(index, image)
                                  }
                                  title="Colocar para perfil"
                                >
                                  <i
                                    className={`fas fa-user ${
                                      image.tipo === "perfil"
                                        ? "text-yellow"
                                        : ""
                                    }`}
                                    title="Colocar para perfil"
                                    alt="Colocar para perfil"
                                  ></i>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <FormGroup>
                          <label>Carregar mais fotos</label>
                        </FormGroup>
                        <TemplateDemo
                          getAllDataAndRefreshImg={getAllDataAndRefreshImg}
                        />
                      </div>
                    </div>
                  )}
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
